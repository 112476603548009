.mr-10 {
  margin-right: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-185 {
  margin-top: 185px;
}

.hide {
  display: none;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  z-index: 999;
  top: 0;
}
/*# sourceMappingURL=global.css.map */